<template>
  <b-card>
    <b-tabs content-class="mt-3" justified>
      <b-tab :title="$t('mainData')" active>
          <b-row>
            <b-col md="8" class="payment-section p-1">
              <g-form @submit="save">
              <b-row>
                <b-col md="4">
                  <!-- code  -->
                  <g-field
                    id="code"
                    type="number"
                    :value.sync="selectedItem.code"
                    label-text="code"
                    name="code"
                  />
                </b-col>
                <!-- parent accounts -->
                <b-col md="4">
                  <b-form-group>
                    <g-field
                      :value.sync="selectedItem.parentId"
                      :dir="isRight ? 'rtl' : 'ltr'"
                      label-text="parentAccount"
                      field="select"
                      name="areaId"
                      :options="parentAccounts"
                      label="arabicName"
                      @change="(v) => {
                        setLevel();
                        checkParent(v);
                        recursiveSearch(selectedNode.children, v);
                      }"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <!-- arabicName  -->
                  <g-field
                    id="arabicName"
                    :value.sync="selectedItem.arabicName"
                    label-text="arabicName"
                    rules="required"
                    name="arabicName"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <!-- englishName  -->
                  <g-field
                   ref="englishName"
                  :value.sync="selectedItem.englishName"
                  label-text="englishName"
                  name="englishName"
                  />
                </b-col>
                <!-- account type  -->
                <b-col md="4">
                  <b-form-group>
                    <g-field
                      :value.sync="selectedItem.type"
                      :dir="isRight ? 'rtl' : 'ltr'"
                      label-text="accountType"
                      field="select"
                      name="accountType"
                      :options="accountTypes"
                      :disabled="selectedItem.id && selectedItem.children !== undefined && selectedItem.children.length > 0"
                      label="arabicName"
                      rules="required"
                      @change="(v) => { changeType(v)}"
                    />
                  </b-form-group>
                </b-col>
                <!-- account type  -->
                <b-col md="4">
                  <b-form-group>
                    <g-field
                      :value.sync="selectedItem.nature"
                      :dir="isRight ? 'rtl' : 'ltr'"
                      label-text="accountNature"
                      field="select"
                      name="accountNatures"
                      :options="accountNatures"
                      label="arabicName"
                      rules="required"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- final account type  -->
                <b-col md="4">
                  <b-form-group>
                    <g-field
                      :value.sync="selectedItem.finalAccountType"
                      :dir="isRight ? 'rtl' : 'ltr'"
                      label-text="finalAccountType"
                      field="select"
                      name="finalAccountType"
                      :options="finalAccountTypes"
                      label="arabicName"
                      rules="required"
                    />
                  </b-form-group>
                </b-col>
                  <b-col md="4">
                  <b-form-group>
                    <g-field
                      :value.sync="selectedItem.groupId"
                      :dir="isRight ? 'rtl' : 'ltr'"
                      label-text="accountGroups"
                      field="select"
                      name="accountGroups"
                      :options="groups"
                      label="arabicName"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <!-- level  -->
                  <g-field
                    id="level"
                    :value.sync="selectedItem.level"
                    label-text="level"
                    name="level"
                    type="number"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-row>
                <!-- prevDepit  -->
                <b-col
                  md="4"
                  v-show="selectedItem.finalAccountType === 'budget'"
                >
                  <g-field
                    id="prevDebit"
                    :value.sync="selectedItem.prevDebit"
                    label-text="prevDebit"
                    name="prevDebit"
                    type="number"
                    :disabled="selectedItem.type === 'main'"
                  />
                </b-col>
                   <!-- prevCredit  -->
                <b-col
                  md="4"
                  v-show="selectedItem.finalAccountType === 'budget'"
                >
                  <g-field
                    id="prevCredit"
                    :value.sync="selectedItem.prevCredit"
                    label-text="prevCredit"
                    name="prevCredit"
                    type="number"
                    :disabled="selectedItem.type == 'main'"
                  />
                </b-col>
              </b-row>
              <b-row>
                 <!-- branches -->
                <b-col md="4">
                  <b-form-group>
                    <g-field
                      :value.sync="selectedItem.branchId"
                      :dir="isRight ? 'rtl' : 'ltr'"
                      label-text="branchName"
                      field="select"
                      name="branch"
                      rules="required"
                      :options="branches"
                      label="arabicName"
                    />
                  </b-form-group>
                </b-col>
                <!-- currentCredit  -->
                <b-col md="4">
                  <g-field
                    id="currentDebit"
                    :value.sync="selectedItem.currentDebit"
                    label-text="currentDebit"
                    name="currentDebit"
                    type="number"
                    disabled
                  />
                </b-col>
                <!-- currentCredit  -->
                <b-col md="4">
                  <g-field
                    id="currentCredit"
                    :value.sync="selectedItem.currentCredit"
                    label-text="currentCredit"
                    name="currentCredit"
                    type="number"
                    disabled
                  />
                </b-col>
                <!-- enableCostCenters  -->
                <b-col md="4">
                  <b-form-group :label="$t('enableAccountCostCenters')">
                    <b-form-checkbox
                      v-model="selectedItem.isDistributable"
                      class="custom-control-primary"
                      :disabled="selectedItem.type == 'main'"
                    >
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- notes -->
                <b-col md="12">
                  <b-form-group :label="$t('notes')">
                    <b-form-textarea
                      id="textarea"
                      v-model="selectedItem.notes"
                      label-text="Notes"
                      rows="1"
                      max-rows="2"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="d-flex justify-content-center sticky-bottom">
                  <b-button
                  data-action-type="new"
                    :disabled="!selectedItem.code"
                    @click="
                      () => {
                        selectedNode.id = 0;
                        selectedItem.id = 0;
                        selectedItem.code = '';
                        selectedItem.arabicName = '';
                        selectedItem.englishName = '';
                        selectedItem.accountType = null;
                        selectedItem.isDistributable = false;
                        selectedItem.prevDebit = 0;
                        selectedItem.prevCredit = 0;
                        selectedItem.currentDebit = 0;
                        selectedItem.currentCredit = 0;
                        setLevel();
                      }
                    "
                    variant="instagram"
                    v-permission="'addAccounts'"
                  >
                    {{ $t('new') }}
                  </b-button>
                  <b-button class="mr-50 ml-50" type="submit" variant="primary" data-action-type="save"
                   :disabled="!selectedItem.code || !selectedItem.arabicName" v-permission="'addAccounts'">
                    {{ $t('save') }}
                  </b-button>
                  <b-button
                    data-action-type="delete"
                    :disabled="!selectedItem.id"
                    @click="remove(selectedItem)"
                    variant="danger"
                    v-permission=" 'deleteAccounts' "
                  >
                    {{ $t('delete') }}
                  </b-button>
                </b-col>
              </b-row>
        </g-form>
            </b-col>
            <b-col md="4" class="payment-section p-2">
              <g-field
                   ref="search"
                  :value.sync="searchText"
                  label-text="search"
                  name="search"
                  />
              <v-treeview
                :value="active"
                :searchText="searchText"
                v-model="items"
                rounded
                :items="items"
                :treeTypes="treeTypes"
                item-key="id"
                :dir="isRight ? 'ltr' : 'ltr'"
                @selected="(v) => (selectedNode = v.model)"
              >
              </v-treeview>
            </b-col>
          </b-row>
      </b-tab>
      <b-tab :title="$t('tabelView')">
        <b-row>
          <b-col md="12">
            <g-table
              ref="chartOfAccount-table"
              :items="tableData"
              :columns="tableColumns"
              :noAction="true"
              perPage="25"
              :totalRows="totalRows"
              :createButton="{ visiable: false }"
              :searchInput="{ visiable: true }"
            >
            </g-table>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import VTreeview from 'v-treeview';
import {
  AccountTypes,
  AccountNatures,
  FinalAccountTypes,
} from '@/libs/acl/Lookups';
import GTable from '@/pages/Shared/Table.vue';

export default {
  components: {
    GTable,
    VTreeview,
  },
  data() {
    return {
      searchText: '',
      active: [],
      open: [],
      currentPage: 1,
      perPage: 25,
      isTableBusy: false,
      totalRows: 0,
      items: [],
      selectedItem: {
        isDistributable: false,
        englishName: ''
      },
      parentAccounts: [],
      accountTypes: AccountTypes,
      accountNatures: AccountNatures,
      finalAccountTypes: FinalAccountTypes,
      tableData: [],
      groups: [],
      openAll: true,
      treeTypes: [
        {
          type: '#',
          max_children: 6,
          max_depth: 4,
          valid_children: [
            'FMM_EMPLOYEE',
            'FMM_SPOUSE',
            'FMM_CHILD',
            'FMM_SIBLING',
            'FMM_PARENT',
            'FMM_PARENT_IN_LAW',
          ],
        },
        {
          type: 'main',
          icon: 'far fa-folder-open',
          valid_children: ['Basic', 'Top-up'],
        },
        {
          type: 'sub',
          icon: 'far fa-file',
          valid_children: ['Basic', 'Top-up'],
        },
        {
          type: 'FMM_CHILD',
          icon: 'far fa-user',
          valid_children: ['Basic', 'Top-up'],
        },
        {
          type: 'FMM_SIBLING',
          icon: 'far fa-user',
          valid_children: ['Basic', 'Top-up'],
        },
        {
          type: 'FMM_PARENT',
          icon: 'far fa-user',
          valid_children: ['Basic', 'Top-up'],
        },
        {
          type: 'FMM_PARENT_IN_LAW',
          icon: 'far fa-user',
          valid_children: ['Basic', 'Top-up'],
        },
        {
          type: 'Basic',
          icon: 'far fa-hospital',
          valid_children: ['Top-up'],
        },
        {
          type: 'Top-up',
          icon: 'far fa-plus-square',
          valid_children: [],
        },
      ],
      contextItems: [],
      selectedNode: null,
      currentBalance: 0,
    };
  },
  watch: {
    selectedNode(newVal) {
      this.selectedItem = newVal;
    },
  },
  computed: {
    tableColumns() {
      return [
        { key: 'code', label: this.$t('code'), sortable: true },
        {
          key: this.isRight ? 'arabicName' : 'englishName',
          label: this.$t('accountName'),
          sortable: true,
        },
        {
          key: this.isRight ? 'natureArabicName' : 'natureEnglishName',
          label: this.$t('accountNature'),
          sortable: true,
          formatter: (key, value, item) => {
            if (item.nature === 'debit') return `<span class="badge badge-light-success">${this.$t('debit')}</span>`;
            return `<span class="badge badge-light-primary">${this.$t('credit')}</span>`;
          },
        },
        {
          key: this.isRight ? 'typeArabicName' : 'typeEnglishName',
          label: this.$t('accountType'),
          sortable: true,
          formatter: (key, value, item) => {
            if (item.type === 'main') return `<span class="badge badge-light-success">${this.$t(`${item.typeArabicName}`)}</span>`;
            return `<span class="badge badge-light-warning">${this.$t(`${item.typeArabicName}`)}</span>`;
          },
        },
        {
          key: this.isRight ? 'finalArabicName' : 'finalEnglishName',
          label: this.$t('finalAccountType'),
          sortable: true,
          formatter: (key, value, item) => {
            if (item.finalAccountType === 'budget') return `<span class="badge badge-light-info">${this.$t(`${item.finalArabicName}`)}</span>`;
            return `<span class="badge badge-light-warning">${this.$t(`${item.finalArabicName}`)}</span>`;
          },
        },
        { key: 'level', label: this.$t('level'), sortable: true },
        {
          key: 'currentBalance',
          label: this.$t('currentBalance'),
          sortable: true,
          type: 'number',
        },
      ];
    },
  },
  mounted() {
    this.getParentAccounts();
    this.getTreeData();
    this.getTableData();
    this.getItems();
  },
  methods: {
    getItems() {
      this.get({ url: 'AccountGroups' }).then((data) => {
        this.groups = data;
      });
    },
    recursiveSearch(arr, target) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === target.id) {
          this.doneAlert({ title: this.$t('noAllowedToAddThisAccount'), type: 'error' });
        this.selectedItem.parentId = null;
            return arr[i];
        }
        if (arr[i].children) {
            const result = this.recursiveSearch(arr[i].children, target);
            if (result) {
                return result;
            }
        }
    }
    return null;
},
    checkParent(v) {
      if (v.id === this.selectedNode.id) {
        this.doneAlert({ title: this.$t('noAllowedToAddParentToItSelf'), type: 'error' });
        this.selectedItem.parentId = null;
      }
    },
    changeType(_item) {
    if (_item.id === 'main') {
      this.selectedItem.isDistributable = false
    }
    },
    beforeRemoveValidation() {
      if (this.selectedItem.children !== undefined && this.selectedItem.children.length > 0) {
        // alert here
        this.doneAlert({ text: this.$t('thisAccountHasChildren'), type: 'error' });
        return false;
      }
      return true;
    },
    setLevel() {
      if (this.selectedNode) {
           if (this.selectedNode.parentId) {
        const exists = this.tableData.some(
        (field) => field.id === this.selectedNode.parentId
      );
        if (exists) {
          this.selectedItem.level = Number(this.selectedNode.level + 1);
        } else {
        this.selectedItem.level = 1;
      }
      }
      } else {
        this.selectedItem.level = 1;
      }
    },
    getParentAccounts() {
      this.get({ url: `Accounts?type=main&branchId=${this.branchId}` }).then((data) => {
        this.parentAccounts = data;
      });
    },
    getTreeData() {
      this.get({ url: 'Accounts/tree' }).then((data) => {
        this.items = data;
      });
    },
    getTableData() {
      this.get({ url: 'Accounts' }).then((data) => { // `Accounts?branchId=${this.branchId}`
        data.forEach((item) => {
          const types = this.accountTypes.find(
            (val) => val.id === item.type
          ) || { arabicName: '', englishName: '' };
          const finalAccount = this.finalAccountTypes.find(
            (val) => val.id === item.finalAccountType
          ) || { arabicName: '', englishName: '' };
          const natureAccount = this.accountNatures.find(
            (val) => val.id === item.nature
          ) || { arabicName: '', englishName: '' };
          item.currentBalance = item.currentDebit
          + item.prevDebit
          - (item.currentCredit + item.prevCredit);
          item.text = `${item.code} - ${item.arabicName}  ( ${item.currentBalance} )`;
          item.typeArabicName = types.arabicName;
          item.typeEnglishName = types.englishName;
          item.finalArabicName = finalAccount.arabicName;
          item.finalEnglishName = finalAccount.englishName;
          item.natureArabicName = natureAccount.arabicName;
          item.natureEnglishName = natureAccount.englishName;
        });
        this.tableData = data;
      });
    },
    beforeSaveValidation() {
      if (this.selectedItem.id > 0 && this.selectedItem.id === this.selectedItem.parentId) {
        // alert here
        this.doneAlert({ text: this.$t('noAllowedToAddParentToItSelf'), type: 'error' });
        return false;
      }
      return true;
    },
    save() {
      if (!this.beforeSaveValidation()) return;
      if (!this.selectedItem.branchId) {
        this.selectedItem.branchId = this.branchId;
      }
      if (this.selectedItem.isDistributable === null) this.selectedItem.isDistributable = false;
      if (this.selectedItem.id > 0) {
        this.update({
          url: 'Accounts',
          data: this.selectedItem,
          id: this.selectedItem.id,
        })
          .then(() => {
            this.doneAlert({ text: this.$t('updatedSuccessfully') });
            this.getTableData();
            this.getTreeData();
            this.getParentAccounts();
            // this.selectedItem = {};
          }).catch(({ data }) => {
          this.doneAlert({ title: data.detail, type: 'error' });
        });
      } else {
        this.create({ url: 'Accounts', data: this.selectedItem })
          .then((data) => {
            this.doneAlert({ text: this.$t('savedSuccessfully') });
            this.selectedItem.id = data.id;
            this.getTableData();
            this.getTreeData();
            this.getParentAccounts();
            // this.selectedItem = {};
          }).catch(({ data }) => {
            if (data.status === 409) {
              this.doneAlert({
              // title: data.detail,
              title: this.$t('Code is already used',
              { code: this.selectedItem.code }),
              type: 'error'
              });
            } else {
              this.doneAlert({
              title: this.$t(data.detail),
              type: 'error'
              });
            }
        });
      }
    },
    remove(item) {
      if (!this.beforeRemoveValidation()) return;
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: 'Accounts', id: item.id }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.selectedItem = {};
            this.getParentAccounts();
            this.getTableData();
            this.getTreeData();
          })
        }
      );
    },
  },
};
</script>

<style>
[data-v-9d9f528c] {
  text-align: right !important;
  padding-left: 0 !important;
}
/* .tree-node{
  padding: 0 !important;
} */
.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.ellipsis:hover {
    overflow: visible;
    white-space: normal;
    transition: all 0.4s ease-in-out;
}
</style>
